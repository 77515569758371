import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutResearcher } from "../../../themes/userq/Layouts/layout_researcher";
import { Helmet } from "react-helmet";
import { Text } from "../../../themes/userq/Text/Text";
import { Icon } from "../../../themes/userq/Icon/Icon";

import {
    getListOfWorkspaces,
    getWorkspaceService,
    updateWorkspaceTitleService,
    deleteWorkspaceService,
} from "../../../../services/workspaces";
import { LoadingIcon } from "../../../themes/userq/Loader/loadingIcon";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { showToast } from "../../../../lib/helpers";
import { Button } from "../../../themes/userq/Button/Button";
import { Input } from "../../../themes/userq/Input/Input";
import "./setting.css";
import DeleteWorkspaceModal from "../modals/delete-workspace-modal";

const Settings = () => {
    ReactSession.setStoreType("localStorage");
    const [pageLoading, setPageLoading] = useState(false);
    const [show_support_panel, setShowSupportPanel] = useState(false);
    const [formValues, setFormValues] = useState({
        workspaceName: "",
    });
    const [formErrors, setFormErrors] = useState({
        workspaceName: null,
        error_class: null,
    });

    const workspaceMaxlength = 30;
    const { workspaceid } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);
    const [workspaceCount, setWorkspaceCount] = useState(0);
    const navigate = useNavigate();
    const [workSpaceTitleChanged, setWorkSpaceTitleChanged] = useState(false);
    const [workspaceTitleLoading, setWorkspaceTitleLoading] = useState(false);
    const [workspaceName, setWorkspaceName] = useState();
    const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState({
        open: false,
    });
    const [totalWorkspacesCount, setTotalWorkspacesCount] = useState(0);
    const [fullName, setFullName] = useState();

    const getWorkspaceApi = () => {
        let token = ReactSession.get("token");
        setPageLoading(true);
        getWorkspaceService(token, workspaceid)
            .then((response) => {
                setPageLoading(false);
                if (response.success) {
                    const user = ReactSession.get("user");
                    // console.log("what is the response..", response);

                    setIsAdmin(response.is_admin);

                    if (response.total_workspaces_count) {
                        setTotalWorkspacesCount(
                            response.total_workspaces_count
                        );
                    }

                    if (response.workspace && response.workspace_team_member) {
                        if (
                            response.workspace_team_member.status !==
                                "invitation_accepted" &&
                            response.workspace_team_member.status !== "active"
                        ) {
                            navigate(
                                "/wsp/" + response.workspace.id + "/noaccess"
                            );
                        }
                    }
                    if (
                        response.workspace &&
                        response.workspace.workspace_team_members.length
                    ) {
                        if (
                            response.workspace_team_member.status !==
                                "invitation_accepted" &&
                            response.workspace_team_member.status !== "active"
                        ) {
                            navigate(
                                "/wsp/" + response.workspace.id + "/noaccess"
                            );
                        }
                    }
                    if (response.workspace.title) {
                        let nameWorkspace = response.workspace.title.slice(
                            0,
                            30
                        );
                        setFormValues({
                            ...formValues,
                            workspaceName: nameWorkspace,
                        });
                        setWorkspaceName(response.workspace.title);
                    } else {
                        let nameWorkspace = (user.first_name + "'s Team").slice(
                            0,
                            30
                        );

                        setFormValues({
                            ...formValues,
                            workspaceName: nameWorkspace,
                        });
                    }
                    getWorkspaceCount();
                } else {
                }
            })
            .catch((error) => {
                // console.error("Error fetching workspace:", error);
                setPageLoading(false);
            });
    };

    const saveWorkspaceTitle = () => {
        if (formValues.workspaceName.trim() && !workspaceTitleLoading) {
            let token = ReactSession.get("token");
            setWorkspaceTitleLoading(true);
            let formData = {};
            formData.title = formValues.workspaceName;
            formData.workspace_id = workspaceid;
            updateWorkspaceTitleService(formData, token)
                .then((response) => {
                    setWorkspaceTitleLoading(false);
                    if (response.success) {
                        setWorkspaceName(formValues.workspaceName);
                        setWorkSpaceTitleChanged(false);
                        showToast(response.message, "success");
                        // console.log('response log', response);
                    } else {
                        showToast(response.message, "error");
                    }
                })
                .catch((error) => {
                    setWorkspaceTitleLoading(false);
                });
        }
    };

    const getWorkspaceCount = () => {
        let token = ReactSession.get("token");
        setPageLoading(true);
        getListOfWorkspaces(token).then((response) => {
            setPageLoading(false);
            if (response.success) {
                const user = ReactSession.get("user");
                if (response.workspace_count) {
                    setWorkspaceCount(+response.workspace_count);
                }
            } else {
                showToast(response.message, "error");
            }
        });
    };

    useEffect(() => {
        const user = ReactSession.get("user");

        setFullName(user.first_name + " " + user.last_name);

        getWorkspaceApi();
    }, [workspaceid]);

    const deleteWorkspace = (signature = null) => {
        if (signature === fullName) {
            let workspace_removal = {
                workspace_id: workspaceid,
                type: "Individual",
                signature: signature,
                name: workspaceName ? workspaceName : "workspace",
            };
            ReactSession.set("workspace_removal", workspace_removal);
            navigate(`/r/delete-workspace`);
        }
    };

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            profileHeader={false}
            activeMenu={"dashboard"}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
            wrapClass="team-setting-page-wrapper"
            workspaceName={workspaceName}
        >
            <Helmet>
                <title>Workspace settings | UserQ</title>
            </Helmet>
            <div className="team-setting-page-inner">
                {pageLoading ? (
                    <>
                        <div className="workspace-page-loader">
                            <div
                                className={
                                    "page-loader accountloading-box creditloading-box"
                                }
                            >
                                <LoadingIcon />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="page-heading-wrap accounts-page--heading">
                            <Text type={"h1"}>Settings</Text>
                        </div>

                        <div className="team-setting-section-hold">
                            <div className="team-setting-left-sec">
                                <div className="team-setting-workspace-name d-flex align-items-center">
                                    <Input
                                        type="text"
                                        id="workspace"
                                        value={formValues.workspaceName}
                                        onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                workspaceName: e.target.value,
                                            });
                                            setWorkSpaceTitleChanged(true);
                                        }}
                                        label={"Workspace name"}
                                        clearField={false}
                                        // error={formErrors["workspaceName"]}
                                        // error_class={formErrors.error_class}
                                        maxLength={workspaceMaxlength}
                                    />
                                    {workSpaceTitleChanged ? (
                                        <button
                                            type="button"
                                            className={
                                                "btn primary-medium " +
                                                (!isAdmin ||
                                                !formValues.workspaceName.trim()
                                                    ? "btn-disabled"
                                                    : "active") +
                                                (workspaceTitleLoading
                                                    ? "no-hover-state"
                                                    : "")
                                            }
                                            onClick={() => {
                                                if (
                                                    isAdmin &&
                                                    formValues.workspaceName.trim()
                                                ) {
                                                    saveWorkspaceTitle();
                                                }
                                            }}
                                            id="r_workspaceName"
                                        >
                                            {workspaceTitleLoading ? (
                                                <div className="micro-loading">
                                                    <div className="three-balls">
                                                        <div className="ball ball1"></div>
                                                        <div className="ball ball2"></div>
                                                        <div className="ball ball3"></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>{"Save"}</>
                                            )}
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className={
                                                "btn primary-medium btn-disabled"
                                            }
                                            id="r_workspaceName"
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>

                                <div className="setting-switch-member-data mt-60">
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"semi-bold-font"}
                                        cssClasses={"mb-20"}
                                    >
                                        Workspace team members
                                    </Text>
                                    <div className="switch-mamber-team">
                                        <Text
                                            type={"subtitle-2"}
                                            cssClasses={"d-block mb-40"}
                                        >
                                            Upgrade to Team plan to add members
                                            to workspace
                                        </Text>

                                        <div className="d-flex align-items-center setting-button-wrap">
                                            <Button
                                                type="primary"
                                                size={`medium`}
                                                cssclass={"white-bg-btn"}
                                                iconLeft={
                                                    <Icon
                                                        value={"group"}
                                                        size={"small"}
                                                    />
                                                }
                                                onClick={() => {
                                                    navigate(
                                                        "/wsp/" +
                                                            workspaceid +
                                                            "/upgrade-workspace"
                                                    );
                                                }}
                                                label="Upgrade workspace"
                                            />

                                            <Button
                                                type="primary"
                                                size={`medium`}
                                                cssclass={"white-bg-btn"}
                                                iconLeft={
                                                    <Icon
                                                        value={"add"}
                                                        size={"small"}
                                                    />
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    let navigate_to =
                                                        "/r/create-team-workspace";

                                                    const searchParams =
                                                        new URLSearchParams(
                                                            window.location.search
                                                        );

                                                    const from =
                                                        searchParams.get(
                                                            "from"
                                                        );

                                                    if (
                                                        searchParams.has(
                                                            "from"
                                                        ) &&
                                                        from !== null &&
                                                        from === "noaccess"
                                                    ) {
                                                        navigate_to =
                                                            "/r/create-team-workspace?from=noaccess";
                                                    }
                                                    navigate(navigate_to);
                                                }}
                                                label="Create new Team workspace"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="delete-leave-workspace-wrap delete-account-section">
                                    <Text
                                        type={"body-text-2"}
                                        fontWeight={"semi-bold-font"}
                                    >
                                        Delete workspace
                                    </Text>
                                    <Text
                                        type={"body-text-3"}
                                        fontWeight={"medium-font"}
                                        cssClasses={"gray-color"}
                                    >
                                        Once you delete the workspace, all tests
                                        and unused credits will be removed.
                                        <br />
                                        <span className="black-color">
                                            This action cannot be undone.
                                        </span>
                                    </Text>

                                    <Button
                                        type="secondary"
                                        size="medium"
                                        iconLeft={
                                            <Icon
                                                value={"delete"}
                                                colorClass={"danger-200-svg"}
                                            />
                                        }
                                        label="Delete workspace"
                                        onClick={() => {
                                            setDeleteWorkspaceModal({
                                                ...deleteWorkspaceModal,
                                                open: true,
                                            });
                                        }}
                                        // state={
                                        //     workspaceCount > 1 ? "" : "disabled"
                                        // }
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <DeleteWorkspaceModal
                open={deleteWorkspaceModal.open}
                close={() => {
                    setDeleteWorkspaceModal({
                        ...deleteWorkspaceModal,
                        open: false,
                    });
                }}
                workspaceCount={totalWorkspacesCount}
                type="individual"
                confirm={(signature) => {
                    if (signature) {
                        deleteWorkspace(signature);
                        setDeleteWorkspaceModal({
                            ...deleteWorkspaceModal,
                            open: false,
                        });
                    }
                }}
                fullName={fullName}
            />
        </LayoutResearcher>
    );
};

export default Settings;
