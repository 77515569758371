import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import { LayoutResearcher } from "../../themes/userq/Layouts/layout_researcher";
import { useState, useEffect, useRef } from "react";
import {
    getLoadMoreSurveyQuestionData,
    getResearcherTestResultData,
    getPrototypeTestResultData,
} from "../../../services/researcher_test_results";
import toast from "react-hot-toast";
import HideParticipant from "./dialog/hide-participant";
import moment from "moment";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { Dropdown } from "react-bootstrap";
import ReportAnswer from "./dialog/report-answer";
import { atom, useRecoilState } from "recoil";
import PieChartSingleChoice from "./components/pie_chart_singlechoice";
import BarChart from "./components/bar_chart";
import BarChartRatingScale from "./components/bar_chart_rating_scale";
import AnyChart from "anychart-react";
import { showToast, roundNumber } from "../../../lib/helpers";
import { LoadingIcon } from "../../loader/loadingIcon";
import { TestResultsHeader } from "../../themes/userq/TestResultsHeader/TestResultsHeader";
import { TestResultsSidebarNavigation } from "./components/test_results_sidebar_navigation";
import { QuestionsDropdown } from "../../themes/userq/QuestionsDropdown/QuestionsDropdown";
import { Icon } from "../../themes/userq/Icon/Icon";
import { Text } from "../../themes/userq/Text/Text";
import { Search } from "../../themes/userq/Search/Search";
import { ByUserTable } from "../../themes/userq/ByUserTable/ByUserTable";
import { ByUserTableLikert } from "../../themes/userq/LikertScaleByUser/ByUserTableLikert";
import { PieStatsBoxSingleChoice } from "./components/pie_stats_box_singlechoice";
import { AggregatedDataTable } from "../../themes/userq/AggregatedDataTable/AggregatedDataTable";
import { HorizontalBarChartStatsBoxMultipleChoice } from "./components/horizontal_bar_chart_stats_box_multiplechoice";
import { LikertScaleChart } from "../../themes/userq/LikertScaleChart/LikertScaleChart";
import { LikertScaleAggregateData } from "../../themes/userq/LikertScaleAggregateData/LikertScaleAggregateData";
import { LikertScaleByUser } from "../../themes/userq/LikertScaleByUser/LikertScaleByUser";
import { HorizontalBarChartStatsBoxRankingScale } from "./components/horizontal_bar_chart_stats_box_rankingscale";
import { PrototypeDropdown } from "../../themes/userq/QuestionsDropdown/PrototypeDropdown";
import { RadioButton } from "../../themes/userq/RadioButton/RadioButton";
import { DropdownComponent } from "../../themes/userq/Dropdown/Dropdown";
import { AutoCompleteSingleSelect } from "../../themes/userq/Dropdown/AutoCompleteSingleSelect";
import h337 from "heatmap.js";
import PrototypeTestFollowupQuestionsResult from "./prototype-test-followup-questions-results";
import { Button } from "../../themes/userq/Button/Button";
import exportAsImage from "../../../lib/exportAsImage";
import html2canvas from "html2canvas";
import { ToastMessage } from "../../themes/userq/ToastMessage/ToastMessage";
import { Helmet } from "react-helmet";
import { nationalities_other } from "../../../data/nationalities_other";
import { SearchDropdown } from "../../themes/userq/SearchDropdown/SearchDropdown";
import { Preview } from "../../themes/userq/Preview/preview";
import { Link as UQLink } from "../../themes/userq/Link/Link";

export function compare(a, b) {
    const value1 = a[1]["averageranking"];
    const value2 = b[1]["averageranking"];

    let comparison = 0;

    if (value1 > value2) {
        comparison = -1;
    } else if (value1 < value2) {
        comparison = 1;
    }
    return comparison;
}

export default function TestResultPrototypeTest({ isSharedLink }) {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const exportImageRef = useRef();
    const exportHeatmapRef = useRef();
    const exportClickmapRef = useRef();
    const exportRef = useRef();

    const imageHeatmapRef = useRef();
    const imageClickmapRef = useRef();

    const refs = useRef([]);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [focusScroll, setFocusScroll] = useState("");
    const [focusScrollChange, setFocusScrollChange] = useState("");
    const scrollRef = useRef(null);

    const handleScroll = (direction, ele, index) => {
        const scrollAmount = 200; // Adjust this value based on your preference

        // console.log(ele.scrollWidth)
        // console.log(ele.scrollLeft)
        // console.log(ele.clientWidth)
        const container = ele;
        // const container = scrollRef.current;
        if (direction === "left") {
            container.scrollLeft -= scrollAmount;
        } else if (direction === "right") {
            container.scrollLeft += scrollAmount;
        }

        setFocusScroll(index);
        setFocusScrollChange(Date.now().toString(36));
    };

    // scroll check for first time load
    const ScrollFirstTimeOnly = (ele, index) => {
        if (ReactSession.get("handleScroll")) {
            if (ele) {
                handleScroll("left", ele, index);

                ReactSession.set("handleScroll", false);
            }
        }
    };

    useEffect(() => {
        if (refs.current[focusScroll]) {
            // console.log('ads')
            //refs.current[focusCategoryId].focus()
        }
    }, [focusScroll, focusScrollChange]);

    const testState = atom({
        key: "testresult-" + test_id,
        default: "",
    });
    const planState = atom({
        key: "testresultplan-" + test_id,
        default: "",
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [questionArray, setQuestionArray] = useState([]);
    const [taskArray, setTaskArray] = useState([]);
    const [prototypeDataArray, setPrototypeDataArray] = useState([]);
    const [activePrototypeDataArray, setActivePrototypeDataArray] =
        useState("");
    const [taskSetting, setTaskSetting] = useState("");
    const [isActive, setActive] = useState(0);
    const [isActiveTask, setActiveTask] = useState(0);
    const [dropdownQuestions, setDropdownQuestions] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [behaviorType, setBehaviorType] = useState("image");
    const [screenOption, setScreenOption] = useState([]);
    const [screenSizes] = useState([
        {
            value: "Fit to width",
            label: "Fit to width",
        },
        {
            value: "Actual size",
            label: "Actual size",
        },
        {
            value: "Fit to canvas",
            label: "Fit to canvas",
        },
    ]);
    const [activeScreenOption, setActiveScreenOption] = useState("");
    const [activeScreenOptionArray, setActiveScreenOptionArray] = useState([]);
    const [activeScreenSize, setActiveScreenSize] = useState("Fit to width");
    const [activeScreenOptionVal, setActiveScreenOptionVal] = useState("");

    const [activeScreenWidth, setActiveScreenWidth] = useState("1000");
    const [activeScreenHeight, setActiveScreenHeight] = useState("1000");

    const [heatmapImageLoading, setHeatmapImageLoading] = useState(false);

    const [baseSixtyFourImage, setBaseSixtyFourImage] = useState("");

    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({
        active: false,
        test_result_id: null,
        hidden_result: false,
    });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({
        active: false,
        answer_id: null,
    });

    const [answerArray, setAnswerArray] = useState([]);
    const [aggregrateArray, setAggregrateArray] = useState([]);
    const [metaData, setMetaData] = useState({
        questionType: "",
        totalResultCount: 0,
        userType: "",
        aggregate_total_voters: 0,
        sort_time: "",
        sort_date: "",
        sort_vote: "",
        load_more: "",
        test_question_id: "",
        page: 1,
    });
    const [search, setSearch] = useState("");
    const [searchLoading, setSearchLoading] = useState("");
    const [chartData, setChartData] = useState({});
    const [rankingchartData, setRankingChartData] = useState({});
    const [matrixArray, setMatrixArray] = useState([]);
    const [matrixheight, setMatrixHeight] = useState(600);
    const [sortedchartData, setSortedChartData] = useState([]);
    const [highestRanking, setHighestRanking] = useState(null);
    const [rankingTableData, setRankingTableData] = useState([]);
    const [searchByUser, setSearchByUser] = useState("");
    const [searchAggregateData, setSearchAggregateData] = useState("");
    const [likertQuestion, setLikertQuestion] = useState({});
    const [clickMapData, setClickMapData] = useState([]);

    const colorArray = [
        "rgb(252,131,189)",
        "rgb(248,160,206)",
        "rgb(245,190,222)",
        "rgb(241,219,239)",
        "rgb(241,219,239)",
    ];

    const [sortLoading, setSortLoading] = useState(false);

    const [resultCount, SetResultCount] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("chart");
    const [activeTaskMenu, setActiveTaskMenu] = useState("overview");
    const [progressDowloadingMessage, setProgressDowloadingMessage] =
        useState(false);
    const [dowloadingFailedMessage, setDowloadingFailedMessage] =
        useState(false);
    const [show_support_panel, setShowSupportPanel] = useState(false);

    const projectState = atom({
        key: "testresultproject-" + test_id,
        default: null,
    });
    const [project, setProject] = useRecoilState(projectState);

    let searchController = useRef();

    const [previewModal, setPreviewModal] = useState({
        open: false,
        img: null,
        loading: false,
    });

    const getTestafterEndTest = () => {
        let token = ReactSession.get("token");

        let guest_result_token = "";

        if (location.pathname.substr(1, 1) === "v") {
            // create guest_result_token
            if (!ReactSession.get("guest_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("guest_result_token", randomToken);
            }

            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";

        if (location.pathname.substr(1, 1) === "a") {
            // create guest_result_token
            if (!ReactSession.get("admin_result_token")) {
                const randomToken = Math.random().toString(36).substr(2, 15);
                ReactSession.set("admin_result_token", randomToken);
            }

            admin_result_token = ReactSession.get("admin_result_token");
        }

        getResearcherTestResultData(
            { test_id: test_id, guest_result_token: "" },
            token
        ).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                navigate("/dashboard");
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success
                            ? "successtoast"
                            : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };

    const jumptoSection = (area, questiontype) => {
        setActiveMenu(area);
        document.getElementById(area + "-" + questiontype).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    };

    function Position(obj) {
        console.log(obj);
        console.log("obj");
        var currenttop = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [currenttop];
        }
    }
    const scrollto = (area) => {
        var fixedElementHeight =
            document.querySelector(".researcher-header").offsetHeight +
            document.querySelector(".test-results-header-researcher")
                .offsetHeight +
            document.querySelector(".prototype-navigator-active-link")
                .offsetHeight;
        console.log(area);
        var offsetTop = Position(document.getElementById(area));
        console.log(offsetTop - fixedElementHeight);
        window.scrollTo({
            top: offsetTop - fixedElementHeight + 0,
            behavior: "smooth",
        });
    };

    const jumptoTaskSection = (area, questiontype) => {
        setActiveTaskMenu(area);
        document.getElementById(area).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    };

    const searchByUserData = (searchByUser) => {
        // setMetaData({...metaData,"search":search})
        setSearchByUser(searchByUser);

        setSearchLoading(true);
        //setTimeout(function () {
        if (searchController.current) {
            searchController.current.abort();
        }
        const controller = new AbortController();

        searchController.current = controller;

        let token = ReactSession.get("token");
        getLoadMoreSurveyQuestionData(
            {
                test_id: test_id,
                test_question_id: metaData.test_question_id,
                page: 1,
                type: metaData.questionType,
                search: searchByUser,
                questiontype: "Survey",
            },
            token,
            searchController.current?.signal
        ).then((response) => {
            if (response.success) {
                setSearchLoading(false);
                setAnswerArray(response.data);
                setMetaData({
                    ...metaData,
                    page: 1,
                    load_more: response.more_records,
                });
            } else {
                setSearchLoading(false);
                showToast(response.message, "error");
            }
        });
    };

    const searchByAggregateData = (searchAggregateData) => {
        setSearchAggregateData(searchAggregateData);
        const dataAnswer = questionArray.filter(
            (item, indexItem) => indexItem + 1 === isActive
        )[0];
        if (dataAnswer) {
            const newAggregrateArray = dataAnswer.aggregate.filter(
                (items) =>
                    items.option
                        .toLowerCase()
                        .indexOf(searchAggregateData.toLowerCase()) > -1
            );
            setAggregrateArray(newAggregrateArray);
        }
    };

    const getTest = () => {
        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            let guest_result_token = "";
            if (location.pathname.substr(1, 1) === "v") {
                // create guest_result_token
                if (!ReactSession.get("guest_result_token")) {
                    const randomToken = Math.random()
                        .toString(36)
                        .substr(2, 15);
                    ReactSession.set("guest_result_token", randomToken);
                }

                guest_result_token = ReactSession.get("guest_result_token");
            }
            let admin_result_token = "";
            if (location.pathname.substr(1, 1) === "a") {
                // create guest_result_token
                if (!ReactSession.get("admin_result_token")) {
                    const randomToken = Math.random()
                        .toString(36)
                        .substr(2, 15);
                    ReactSession.set("admin_result_token", randomToken);
                }

                admin_result_token = ReactSession.get("admin_result_token");
            }

            getResearcherTestResultData(
                {
                    test_id: test_id,
                    guest_result_token: guest_result_token,
                    admin_result_token: admin_result_token,
                },
                token
            ).then((response) => {
                setLoading(false);

                if (response.success) {
                    if (response.workspace_project_team_member) {
                        if (
                            response.workspace_project_team_member.status !=
                            "active"
                        ) {
                            navigate(
                                "/wsp/" +
                                    response.workspace_project_team_member
                                        .workspace_id +
                                    "/p/" +
                                    response.workspace_project_team_member
                                        .workspace_project_id +
                                    "/project-no-access"
                            );
                        }
                    }
                    if (response.test && response.test.workspace_project) {
                        setProject(response.test.workspace_project);
                    }
                    // check test is available for sharing
                    if (
                        location.pathname.substr(1, 1) === "v" &&
                        response.is_share_result
                    ) {
                        navigate(`/v/results/${test_id}/results-not-available`);
                    }

                    // check if guest user not validate there password
                    if (
                        location.pathname.substr(1, 1) === "v" &&
                        response.test.password &&
                        !response.test_password_verify
                    ) {
                        navigate(`/v/results/${test_id}/password`);
                    }

                    setTest(response.test);
                    getPrototypeTest();
                } else {
                    if (response.message == "Project not found.") {
                        navigate("/404");
                    } else if (response.message == "Test Record Not found") {
                        navigate("/404");
                    } else {

                        if(location.pathname.substr(1, 1) === 'v'){

                            if(response.test_shared_results && !response.test_password_verify){
                                return navigate(`/v/results/${test_id}/password`);
                            }
                            else if(!response.test_shared_results){
                                return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        if(location.pathname.substr(1, 1) === 'a'){

                            if(!response.test_results_available){
                                return navigate(`/v/results/${test_id}/results-not-available`);
                            }
                            
                        }
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: response.success
                                    ? "successtoast"
                                    : "errortoast",
                                position: "bottom-center",
                                duration: 2000,
                            }
                        );
                    }
                }
            });
        } else {
            getPrototypeTest();
        }
    };

    const getPrototypeTest = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        let guest_result_token = "";
        if (location.pathname.substr(1, 1) === "v") {
            guest_result_token = ReactSession.get("guest_result_token");
        }
        let admin_result_token = "";
        if (location.pathname.substr(1, 1) === "a") {
            admin_result_token = ReactSession.get("admin_result_token");
        }

        getPrototypeTestResultData(
            {
                test_id: test_id,
                guest_result_token: guest_result_token,
                admin_result_token: admin_result_token,
            },
            token
        ).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                console.log(response);
                setTaskArray(response.test.prototype_test_tasks);
                setTaskSetting(response.test.prototype_test_setting);
                // setQuestionArray(response.data);
                // changeActiveQuestion(1, response.data);
                setPrototypeDataArray(response.data);
                changeActiveTask(1, response.data);
                SetResultCount(response.resultcount);

                if (!response.some_nodes_failed) {
                    setProgressDowloadingMessage(
                        response.some_nodes_downloading
                    );
                }
                let prototype_download_failed_messages = ReactSession.get(
                    "prototype_download_failed_messages"
                ) || { test_id: false };

                if (!prototype_download_failed_messages[test_id]) {
                    setDowloadingFailedMessage(response.some_nodes_failed);
                }
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success
                            ? "successtoast"
                            : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };

    useEffect(() => {
        //document.title = process.env.REACT_APP_NAME + " - prototype test";
        getTest();
    }, [location]);

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    };

    const showPreQuestion = (type) => {
        if (type === "increase") {
            // decrease active question length
            if (isActive !== 1) {
                changeActiveQuestion(isActive - 1, questionArray);
            }
        } else {
            // increase active question length
            if (questionArray.length !== isActive) {
                changeActiveQuestion(isActive + 1, questionArray);
            }
        }
    };

    const changeActiveTask = (index, data) => {
        setActive(index);
        setActiveTask(index - 1); // set active task
        setDropdownQuestions(false); // hide dropdown value
        setSearch(""); // hide dropdown value

        // filter data from array
        const dataAnswer = data.find(
            (item, indexItem) => indexItem + 1 === index
        );
        if (dataAnswer) {
            setActivePrototypeDataArray(dataAnswer);
            setBehaviorType("image");

            // set active screen option behavoiur option
            var screenOptionArray = [];
            dataAnswer.behaviourData.forEach((items) => {
                screenOptionArray.push({
                    label: items.name,
                    value: items.name,
                    // 'value': items.node_id,
                    node_id: items.node_id,
                    img: items.img,
                    is_placeholder: items.is_placeholder,
                });
            });
            // console.log(screenOptionArray);
            setFocusScrollChange(Date.now().toString(36));

            setActiveScreenOptionArray(screenOptionArray);
            if (screenOptionArray.length > 0) {
                setActiveScreenOption(screenOptionArray[0].node_id);
                // setActiveScreenOption(screenOptionArray[0].value);
                setActiveScreenOptionVal(screenOptionArray[0].label);
                setScreenOption(screenOptionArray);
            }

            // set image for base64
            // convertImageToBase64('https://www.freecodecamp.org/news/content/images/size/w2000/2021/09/freeCodeCamp-Cover-4.png',function(data) {
            //     // any = data;
            //     console.log(data)
            // })

            // set heatmap data
            //HeatmapCalculations(dataAnswer)

            ReactSession.set("handleScroll", "active");
        }
        //
    };

    useEffect(() => {
        setTimeout(() => {
            // if(behaviorType !== 'image')
            var is_calculation = false;

            activePrototypeDataArray.behaviourData.forEach(function (item) {
                if (item.node_id == activeScreenOption) {
                    if (item.is_placeholder == "no") {
                        is_calculation = true;
                    }
                }
            });
            if (is_calculation) {
                HeatmapCalculations(activePrototypeDataArray.behaviourData);
            }
        }, 500);
    }, [activeScreenOption, behaviorType]);

    const HeatmapCalculations = (dataItems) => {
        document
            .querySelectorAll(".heatmap-canvas")
            .forEach((el) => el.remove());

        //console.log(imageClickmapRef)
        //console.log(imageHeatmapRef)

        // set width & height of heatmap & clickmap
        if (behaviorType === "heatmap") {
            setActiveScreenWidth(imageHeatmapRef.current.clientWidth);
            setActiveScreenHeight(imageHeatmapRef.current.clientHeight);
        } else if (behaviorType === "clickmap") {
            setActiveScreenWidth(imageClickmapRef.current.clientWidth);
            setActiveScreenHeight(imageClickmapRef.current.clientHeight);
        }

        if (behaviorType === "heatmap") {
            var heatmapInstance = h337.create({
                // only container is required, the rest will be defaults
                container: document.querySelector(".heatmapImage"),
                //radius: 10,
                // maxOpacity: .5,
                //minOpacity: 0,
                // blur: .75,
                width: imageHeatmapRef.current.clientWidth,
                height: imageHeatmapRef.current.clientHeight,
                gradient: {
                    // enter n keys between 0 and 1 here
                    // for gradient color customization
                    ".5": "#9759CB",
                    ".8": "#FFB800",
                    ".9": "#e6542a",
                },
            });

            // now generate some random data
            var points = [];
            var max = 0;
            var width = 840;
            var height = 400;
            var len = 200;

            // while (len--) {
            //     var val = Math.floor(Math.random()*100);
            //     max = Math.max(max, val);
            //     var point = {
            //         x: Math.floor(Math.random()*width),
            //         y: Math.floor(Math.random()*height),
            //         value: val
            //     };
            //     points.push(point);
            // }
            dataItems.forEach((items) => {
                if (items.node_id === activeScreenOption) {
                    items.heatMapDataArray.forEach((heat) => {
                        var point = {
                            x: heat.x,
                            y: heat.y,
                            value: heat.value,
                        };
                        points.push(point);
                    });
                }
            });
            // heatmap data format
            var data = {
                max: max,
                data: points,
            };
            // console.log(points)
            //setClickMapData(points);
            // if you have a set of datapoints always use setData instead of addData
            // for data initialization
            heatmapInstance.setData(data);
        }

        //console.log(heatmapInstance.getDataURL())

        if (behaviorType === "clickmap") {
            // clickmap data initialization
            var clickmapInstance = h337.create({
                container: document.querySelector(".clickMapImage"),
                radius: 5,
                maxOpacity: 1,
                minOpacity: 0,
                blur: 0,
                width: imageClickmapRef.current.clientWidth,
                height: imageClickmapRef.current.clientHeight,
                gradient: {
                    // enter n keys between 0 and 1 here
                    // for gradient color customization
                    // '.5': 'blue',
                    // '.8': 'red',
                    ".95": "#E32F83",
                },
            });

            var points = [];
            var max = 0;
            var width = 840;
            var height = 400;
            var len = 200;

            dataItems.forEach((items) => {
                if (items.node_id === activeScreenOption) {
                    items.heatMapDataArray.forEach((heat) => {
                        var point = {
                            x: heat.x,
                            y: heat.y,
                            value: heat.value,
                        };
                        points.push(point);
                    });
                }
            });

            var data = {
                max: max,
                data: points,
            };
            clickmapInstance.setData(data);
        }

        if (behaviorType === "heatmap") {
            // convert image to base64
            setHeatmapImageLoading(true);
            convertImageToBase64(
                process.env.REACT_APP_API_END_POINT +
                    "prototype-test-node-image?image=" +
                    imageHeatmapRef.current.src,
                function (data) {
                    setHeatmapImageLoading(false);
                    const imgs = [
                        {
                            // uri: process.env.REACT_APP_API_END_POINT+'prototype-test-node-image?image='+imageHeatmapRef.current.src,
                            //uri: 'https://corsproxy.io/?https%3A%2F%2Fuat.userq.com%2Fuploads%2Fprototype_test_tasks%2F214%2Fnodes%2F3611%2Fnode_1681192509.jpg',
                            uri: data,
                            x: 0,
                            y: 0,
                            sw: imageHeatmapRef.current.clientWidth,
                            sh: imageHeatmapRef.current.clientHeight,
                            type: "image",
                        },
                        {
                            uri: heatmapInstance.getDataURL(),
                            x: 0,
                            y: 0,
                            sw: imageHeatmapRef.current.clientWidth,
                            sh: imageHeatmapRef.current.clientHeight,
                            type: "heatmap",
                        },
                    ];

                    imgs.forEach(depict);

                    //heatmap-clickmap-canvas-wrapper
                    document
                        .getElementById("heatmap-clickmap-canvas-wrapper")
                        .scrollIntoView({
                            behavior: "smooth",
                            block: "top",
                        });
                }
            );
        }

        if (behaviorType === "clickmap") {
            setHeatmapImageLoading(true);
            convertImageToBase64(
                process.env.REACT_APP_API_END_POINT +
                    "prototype-test-node-image?image=" +
                    imageClickmapRef.current.src,
                function (data) {
                    setHeatmapImageLoading(false);
                    const imgs = [
                        {
                            //uri: process.env.REACT_APP_API_END_POINT+'prototype-test-node-image?image='+imageClickmapRef.current.src,
                            //uri: 'https://corsproxy.io/?https%3A%2F%2Fuat.userq.com%2Fuploads%2Fprototype_test_tasks%2F214%2Fnodes%2F3611%2Fnode_1681192509.jpg',
                            uri: data,
                            x: 0,
                            y: 0,
                            sw: imageClickmapRef.current.clientWidth,
                            sh: imageClickmapRef.current.clientHeight,
                            type: "image",
                        },
                        {
                            uri: clickmapInstance.getDataURL(),
                            x: 0,
                            y: 0,
                            sw: imageClickmapRef.current.clientWidth,
                            sh: imageClickmapRef.current.clientHeight,
                            type: "heatmap",
                        },
                    ];

                    imgs.forEach(depict);

                    //heatmap-clickmap-canvas-wrapper
                    document
                        .getElementById("heatmap-clickmap-canvas-wrapper")
                        .scrollIntoView({
                            behavior: "smooth",
                            block: "top",
                        });
                }
            );

            //console.log(imgs)
        }
    };

    // custom code data set
    const getContext = () =>
        document.getElementById("my-canvas").getContext("2d");

    const loadImage = (url, type) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} ${type} fail`));
            img.src = url;
            if (type === "image") {
                //img.crossOrigin = 'anonymous'
            }
        });
    };

    const depict = (options) => {
        const ctx = getContext();
        // And this is the key to this solution
        // Always remember to make a copy of original object, then it just works :)
        const myOptions = Object.assign({}, options);
        return loadImage(myOptions.uri, myOptions.type).then((img) => {
            ctx.drawImage(
                img,
                myOptions.x,
                myOptions.y,
                myOptions.sw,
                myOptions.sh
            );
        });
    };

    const changeActiveQuestion = (index, data) => {
        setActive(index);
        setActiveTask(index - 1); // set active task
        setDropdownQuestions(false); // hide dropdown value
        setSearch(""); // hide dropdown value

        // filter data from array
        const dataAnswer = data.filter(
            (item, indexItem) => indexItem + 1 === index
        )[0];

        if (dataAnswer) {
            setAnswerArray(dataAnswer.result); // set all test result data list
            setAggregrateArray(dataAnswer.aggregate);
            if (dataAnswer.question_type == "multiplechoice") {
                setChartData(dataAnswer.chartDataMultipleChoice);
            } else {
                setChartData(dataAnswer.chartData);
            }
            if (dataAnswer.question_type == "rankingscale") {
                setRankingChartData(dataAnswer.rankingchartData);
                setRankingTableData([dataAnswer.rankingtableData]);
            }
            if (dataAnswer.question_type == "likertscale") {
                let matrixData = [];

                dataAnswer.matrix.forEach((items) => {
                    var totalheat = 0;
                    dataAnswer.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat;
                        }
                    });
                    var fill = "";
                    if (
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 >=
                        80
                    ) {
                        fill = "#5C208D";
                    } else if (
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 >=
                            60 &&
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 <
                            80
                    ) {
                        fill = "#793EAB";
                    } else if (
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 >=
                            40 &&
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 <
                            60
                    ) {
                        fill = "#9759CB";
                    } else if (
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 >=
                            20 &&
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 <
                            40
                    ) {
                        fill = "#BF8EE8";
                    } else if (
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 >
                            0 &&
                        Math.round(((items.heat * 100) / totalheat) * 100) /
                            100 <
                            20
                    ) {
                        fill = "#EFDCFF";
                    } else {
                        fill = "#E9E9E9";
                    }

                    matrixData.push({
                        x: items.x,
                        // x: items.x.length > 15 ? (test.language == "ar" ? "..." + items.x.substring(0, 14) : items.x.substring(0, 14) + "..") : items.x,
                        y: items.y,
                        // y: items.y.length > 20 ? (test.language == "ar" ? "..." + items.y.substring(0, 19) : items.y.substring(0, 19) + "...") : items.y,
                        // heat: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "%",
                        heat: items.heat,
                        fill: fill,
                        // participants:items.heat>1?items.heat+" Participants":items.heat+" Participant",
                        participants:
                            Math.round(((items.heat * 100) / totalheat) * 100) /
                                100 +
                            "% Participants",
                        percent_participants:
                            Math.round(((items.heat * 100) / totalheat) * 100) /
                            100,
                    });
                });

                setMatrixArray(matrixData);
                setMatrixHeight(dataAnswer.subquestionscount * 30 + 50);
                setLikertQuestion(dataAnswer.questions);
            }

            let sortable = [];
            for (var option in dataAnswer.chartData) {
                sortable.push([option, dataAnswer.chartData[option]]);
            }

            sortable.sort(function (a, b) {
                return b[1] - a[1];
            });

            setSortedChartData(sortable);

            setMetaData({
                anyOptionHaveImage: dataAnswer.any_option_have_image
                    ? dataAnswer.any_option_have_image
                    : false,
                questionType: dataAnswer.question_type,
                totalResultCount: dataAnswer.result_count,
                userType:
                    dataAnswer.question_type === "free" ? "by-user" : "chart",
                aggregate_total_voters: dataAnswer.aggregate_total_voters,
                sort_time: "",
                sort_date: "",
                sort_vote: "",
                load_more: dataAnswer.result_count > 10 ? true : false,
                test_question_id: dataAnswer.id,
                page: 1,
            });
        }
    };

    const calculateTime = (time) => {
        var d = Math.floor(time / (3600 * 24));
        var h = Math.floor((time % (3600 * 24)) / 3600);
        var m = Math.floor((time % 3600) / 60);
        var s = Math.floor(time % 60);

        if (m > 0) {
            return `${m}min ${s}sec`;
        } else if (s > 0) {
            return ` ${s}sec`;
        } else {
            return "-";
        }
    };

    const averageTaskTime = (timeSpend) => {
        // const avearagetime = parseInt((timeSpend) / (resultCount));

        var averageTimeSpendMinute = Math.floor(timeSpend / (resultCount * 60));
        var averageTimeSpendSeconds = ((timeSpend / resultCount) % 60).toFixed(
            0
        );

        if (averageTimeSpendSeconds.length === 1) {
            return (
                averageTimeSpendMinute + "m 0" + averageTimeSpendSeconds + "s"
            );
        } else {
            return (
                averageTimeSpendMinute + "m " + averageTimeSpendSeconds + "s"
            );
        }

        // var d = Math.floor(avearagetime / (3600 * 24));
        // var h = Math.floor(avearagetime % (3600 * 24) / 3600);
        // var m = Math.floor(avearagetime % 3600 / 60);
        // var s = Math.floor(avearagetime % 60);
        //
        //
        // var timeData = '';
        // if (d > 0) {
        //     timeData = (`${d}:${h}:${m}:${s}`);
        //
        // } else if (h > 0) {
        //
        //     timeData = (`${h}:${m}:${s}`);
        //
        // } else if (m > 0) {
        //     timeData = (`${m}:${s}`);
        //
        // } else if (s > 0) {
        //     if(s.length === 1){
        //         timeData = (`0:0${s}`);
        //     }else{
        //         timeData = (`0:${s}`);
        //     }
        //
        // } else {
        //     timeData = ('0:0');
        //
        // }
        //
        // return timeData;
    };

    const convertImageToBase64 = (imgUrl, callback) => {
        //  console.log(imgUrl)
        const image = new Image();
        image.crossOrigin = "anonymous";
        var imageBASE = "";
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            //console.log('ads')
            callback && callback(dataUrl);
            setBaseSixtyFourImage(dataUrl);
            var imageBASE = dataUrl;
            //console.log(dataUrl)
            // return dataUrl;
        };
        image.src = imgUrl;
    };

    function getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        console.log(dataURL.replace(/^data:image\/?[A-z]*;base64,/));

        return dataURL.replace(/^data:image\/?[A-z]*;base64,/);
    }

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    };

    const downloadPng = (type) => {
        //  var any = '';
        //  convertImageToBase64('https://miro.medium.com/fit/c/64/64/1*3wTm70KPAVCovuotmFReJA.png',function(data) {
        //      any = data;
        //      console.log(data)
        //   })
        // //
        //  console.log(baseSixtyFourImage)
        //getBase64Image(document.getElementById("imageid"))
        // getBase64FromUrl('https://lh3.googleusercontent.com/i7cTyGnCwLIJhT1t2YpLW-zHt8ZKalgQiqfrYnZQl975-ygD_0mOXaYZMzekfKW_ydHRutDbNzeqpWoLkFR4Yx2Z2bgNj2XskKJrfw8').then((res)=>{
        //     console.log(res)
        // })
        if (type === "heatmap") {
            var htmlContent = exportRef.current;
            console.log(htmlContent);
            exportAsImage(htmlContent, "heatmap");
        } else if (type === "clickmap") {
            var htmlContent = exportRef.current;

            console.log(htmlContent);
            exportAsImage(htmlContent, "clickmap");
        } else {
            var htmlContent = exportRef.current;

            console.log(htmlContent);
            exportAsImage(htmlContent, "image");
        }
        //var htmlContent = exportSimilarityRef.current;
        //var htmlContent = exportResultGridSvgRef.current.getElementsByTagName('div')[0].innerHTML;

        //htmlContent = htmlContent.replace(/(<(?:div)[^<]*) (class="card-sorting-matrix-y-axis") (style="[^"]*")([^>]*>)/gi,'<div class="card-sorting-matrix-y-axis" style="width: 160px;padding-right: 10px;font-weight: 500;font-size: 14px;line-height: 25px;color: rgb(0, 0, 0)">');
        // console.log(htmlContent);
        //const blob = new Blob([htmlContent], { type: "image/png" });
        //exportAsImage(htmlContent, "result-grid")
    };

    const checkIfDownloadDisabled = () => {
        var is_calculation = false;

        activePrototypeDataArray.behaviourData.forEach(function (item) {
            if (item.node_id == activeScreenOption) {
                if (item.is_placeholder == "no") {
                    is_calculation = true;
                }
            }
        });
        return is_calculation;
    };
    const handleDownload = () => {
        var is_calculation = false;

        activePrototypeDataArray.behaviourData.forEach(function (item) {
            if (item.node_id == activeScreenOption) {
                if (item.is_placeholder == "no") {
                    is_calculation = true;
                }
            }
        });
        if (is_calculation) {
            html2canvas(exportRef.current, {
                // useCORS: true,
                allowTaint: true,
                // foreignObjectRendering: true
            }).then((canvas) => {
                console.log(canvas);
                const link = document.createElement("a");
                if (behaviorType === "heatmap") {
                    link.download = "heatmap.png";
                } else {
                    link.download = "clickmap.png";
                }

                link.href = canvas.toDataURL("image/png");
                link.click();
            });
        } /*else {
            showToast("The image is not available to download. Please try again later.", "error");
        }*/
    };

    const checkPrototypeWidth = (items, index, refData) => {
        if (index === 2) console.log(refData.scrollLeft);
    };

    return (
        <LayoutResearcher
            isSharedLink={isSharedLink}
            fixed_header_target={true}
            wrapClass={
                "researcher-test-view-overflow overflow-visible researcher-resultwrap"
            }
            resultHeaderClass={"result-header"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
            openSupportPanel={show_support_panel}
            hideSupportPanel={() => {
                setShowSupportPanel(false);
            }}
        >
            <Helmet>
                <title>Analyse prototype test | UserQ</title>
            </Helmet>
            {isLoading && (
                <div className="loader_section full-page-loader">
                    <LoadingIcon />
                </div>
            )}

            {!isLoading && test && (
                <div className="three-column-layout two-column-layout">
                    <TestResultsHeader
                        project={project}
                        isSharedLink={isSharedLink}
                        test={test}
                        endedOn={
                            test.status === "completed"
                                ? moment.utc(test.ended_on).format("DD-MM-Y")
                                : ""
                        }
                        callbackTopbar={callbackTopbar}
                    />
                    <div className="three-column-layout-body">
                        <div className="three-column-layout-left">
                            <TestResultsSidebarNavigation
                                isSharedLink={isSharedLink}
                                methodology={
                                    test.methodology === "Five Seconds Test"
                                        ? "5 second test"
                                        : test.methodology
                                }
                                selected_index={4}
                                test_id={
                                    location.pathname.substr(1, 1) === "v"
                                        ? test_id
                                        : test.id
                                }
                                screening={
                                    test &&
                                    test.screening_questions &&
                                    test.screening_questions.length > 0
                                        ? "Yes"
                                        : "No"
                                }
                            />
                        </div>
                        <div className="two-column-right-side">
                            {sectionLoading && (
                                <div className="page-loader accountloading-box result-sections-loader">
                                    <LoadingIcon />
                                </div>
                            )}
                            {!sectionLoading && resultCount !== 0 && (
                                <div className="bd-question-view-wrap">
                                    <div className="prototype-results-top-bar prototype-result-space-hold">
                                        <div className="question-result-header question-result-data is-sticky">
                                            <PrototypeDropdown
                                                questionArray={taskArray}
                                                changeActiveQuestionProp={(
                                                    index
                                                ) => {
                                                    changeActiveTask(
                                                        index,
                                                        prototypeDataArray
                                                    );
                                                    scrollto("overview");
                                                }}
                                                dropdownOpen={(state) =>
                                                    setDropdownOpen(state)
                                                }
                                                resultCount={resultCount}
                                                taskSetting={
                                                    taskSetting &&
                                                    taskSetting.objective
                                                }
                                                test={test}
                                            />

                                            <div className="accountnaviagtion-outer result-navigation-outer prototype-navigator-active-link">
                                                <span
                                                    className={`${
                                                        activeTaskMenu ==
                                                        "overview"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        jumptoTaskSection(
                                                            "overview",
                                                            1
                                                        );
                                                    }}
                                                >
                                                    <a data-href={`#overview`}>
                                                        Overview
                                                    </a>
                                                </span>
                                                <span
                                                    className={`${
                                                        activeTaskMenu ==
                                                        "common-path"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        jumptoTaskSection(
                                                            "common-path",
                                                            1
                                                        );
                                                    }}
                                                >
                                                    <a
                                                        data-href={`#common-path`}
                                                    >
                                                        Common path
                                                    </a>
                                                </span>
                                                <span
                                                    className={`${
                                                        activeTaskMenu ==
                                                        "behavior"
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        jumptoTaskSection(
                                                            "behavior",
                                                            1
                                                        );
                                                    }}
                                                >
                                                    <a data-href={`#behavior`}>
                                                        Behaviour
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={`question-result-data   ${
                                                dropdownOpen
                                                    ? "black-overlay"
                                                    : ""
                                            }`}
                                        >
                                            {/*{metaData.totalResultCount>=1 &&*/}

                                            {/*}*/}

                                            {/*Overview start*/}
                                            <div className="prototype-result-overview-wrap">
                                                <div
                                                    className={`prototype-overview`}
                                                >
                                                    <div
                                                        className={`prototype-overview-header`}
                                                        id={"overview"}
                                                    >
                                                        <Text
                                                            type={"h4"}
                                                            children={`Overview`}
                                                        />
                                                    </div>

                                                    <div
                                                        className={`prototype-overview-content pt-40 pb-40`}
                                                    >
                                                        <div className={`row`}>
                                                            {/*completion & skip rate will be shown only for task flow prototype*/}
                                                            {taskSetting &&
                                                                taskSetting.objective ===
                                                                    "closed" && (
                                                                    <>
                                                                        <div
                                                                            className={`col-lg-3 col-md-6`}
                                                                        >
                                                                            <div className=" prototype-overview-inner-data completion-rate">
                                                                                <Text
                                                                                    type={
                                                                                        "body-text-1"
                                                                                    }
                                                                                    fontWeight={
                                                                                        "medium-font"
                                                                                    }
                                                                                    children={
                                                                                        "Completion rate"
                                                                                    }
                                                                                />

                                                                                <div
                                                                                    className={`data-percent d-flex`}
                                                                                >
                                                                                    {/*isActive*/}
                                                                                    <Icon
                                                                                        size={
                                                                                            "large"
                                                                                        }
                                                                                        value={
                                                                                            "success"
                                                                                        }
                                                                                        colorClass={
                                                                                            "success-green-200-svg"
                                                                                        }
                                                                                    />
                                                                                    <Text
                                                                                        type={
                                                                                            "h3"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "green-text"
                                                                                        }
                                                                                        children={`${roundNumber(
                                                                                            activePrototypeDataArray.completedTaskCountPercentage,
                                                                                            1
                                                                                        )}%`}
                                                                                    />
                                                                                </div>

                                                                                <Text
                                                                                    type={
                                                                                        "caption"
                                                                                    }
                                                                                    cssClasses={
                                                                                        "gray-color"
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        activePrototypeDataArray.completedTaskCount
                                                                                    }{" "}
                                                                                    out
                                                                                    of{" "}
                                                                                    {
                                                                                        resultCount
                                                                                    }{" "}
                                                                                    have
                                                                                    completed
                                                                                    your
                                                                                    task
                                                                                </Text>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className={`col-lg-3 col-md-6`}
                                                                        >
                                                                            <div className=" prototype-overview-inner-data skip-rate">
                                                                                <Text
                                                                                    type={
                                                                                        "body-text-1"
                                                                                    }
                                                                                    fontWeight={
                                                                                        "medium-font"
                                                                                    }
                                                                                    children={
                                                                                        "Skip rate"
                                                                                    }
                                                                                />

                                                                                <div
                                                                                    className={`data-percent d-flex`}
                                                                                >
                                                                                    <Icon
                                                                                        size={
                                                                                            "large"
                                                                                        }
                                                                                        value={
                                                                                            "Forward"
                                                                                        }
                                                                                        colorClass={
                                                                                            "gray-700-svg"
                                                                                        }
                                                                                    />
                                                                                    <Text
                                                                                        type={
                                                                                            "h3"
                                                                                        }
                                                                                        cssClasses={
                                                                                            "light-dark-text"
                                                                                        }
                                                                                        children={`${roundNumber(
                                                                                            activePrototypeDataArray.skipTaskCountPercentage,
                                                                                            1
                                                                                        )}%`}
                                                                                    />
                                                                                </div>

                                                                                <Text
                                                                                    type={
                                                                                        "caption"
                                                                                    }
                                                                                    cssClasses={
                                                                                        "gray-color"
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        activePrototypeDataArray.skipTaskCount
                                                                                    }{" "}
                                                                                    out
                                                                                    of{" "}
                                                                                    {
                                                                                        resultCount
                                                                                    }{" "}
                                                                                    have
                                                                                    left
                                                                                    or
                                                                                    given
                                                                                    up
                                                                                    on
                                                                                    your
                                                                                    task
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                            <div
                                                                className={`col-lg-3 col-md-6`}
                                                            >
                                                                <div className=" prototype-overview-inner-data average-time">
                                                                    <Text
                                                                        type={
                                                                            "body-text-1"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        children={
                                                                            "Average time spent"
                                                                        }
                                                                    />

                                                                    <div
                                                                        className={`data-percent d-flex`}
                                                                    >
                                                                        <Icon
                                                                            size={
                                                                                "large"
                                                                            }
                                                                            value={
                                                                                "time"
                                                                            }
                                                                        />
                                                                        <Text
                                                                            type={
                                                                                "h3"
                                                                            }
                                                                            children={`${averageTaskTime(
                                                                                activePrototypeDataArray.prototypeTaskResultsAverageTime
                                                                            )}`}
                                                                        />
                                                                    </div>

                                                                    <Text
                                                                        type={
                                                                            "caption"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color"
                                                                        }
                                                                    >
                                                                        The
                                                                        average
                                                                        time
                                                                        spent on
                                                                        this
                                                                        task is{" "}
                                                                        {averageTaskTime(
                                                                            activePrototypeDataArray.prototypeTaskResultsAverageTime
                                                                        )}
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className={`col-lg-3 col-md-6`}
                                                            >
                                                                <div className=" prototype-overview-inner-data average-click">
                                                                    <Text
                                                                        type={
                                                                            "body-text-1"
                                                                        }
                                                                        fontWeight={
                                                                            "medium-font"
                                                                        }
                                                                        children={
                                                                            "Average clicks"
                                                                        }
                                                                    />

                                                                    <div
                                                                        className={`data-percent d-flex`}
                                                                    >
                                                                        <Icon
                                                                            size={
                                                                                "large"
                                                                            }
                                                                            value={
                                                                                "click"
                                                                            }
                                                                        />
                                                                        <Text
                                                                            type={
                                                                                "h3"
                                                                            }
                                                                            children={Math.ceil(
                                                                                activePrototypeDataArray.average_click
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <Text
                                                                        type={
                                                                            "caption"
                                                                        }
                                                                        cssClasses={
                                                                            "gray-color"
                                                                        }
                                                                    >
                                                                        The
                                                                        average
                                                                        clicks
                                                                        was{" "}
                                                                        {Math.ceil(
                                                                            activePrototypeDataArray.average_click
                                                                        )}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*Overview end*/}

                                                {/*Common path start*/}
                                                <div
                                                    className={`prototype-common-path pb-20`}
                                                    id={"common-path"}
                                                >
                                                    <div
                                                        className={`common-path-header`}
                                                    >
                                                        <Text
                                                            type={"h4"}
                                                            children={
                                                                "Common paths"
                                                            }
                                                        />
                                                        <Text
                                                            type={"body-text-2"}
                                                            cssClasses={
                                                                "light-dark-text mt-20"
                                                            }
                                                            fontWeight={
                                                                "medium-font"
                                                            }
                                                            children={
                                                                "A common path is the series of events performed or experienced by the majority of the users"
                                                            }
                                                        />
                                                    </div>

                                                    <div
                                                        className={`common-path-content`}
                                                    >
                                                        {progressDowloadingMessage && (
                                                            <div className="fixed-toast-wrapper">
                                                                <ToastMessage
                                                                    type={
                                                                        "info"
                                                                    }
                                                                    closable={
                                                                        false
                                                                    }
                                                                    message={
                                                                        <>
                                                                            Loading
                                                                            the
                                                                            prototype
                                                                            images
                                                                            is
                                                                            taking
                                                                            longer
                                                                            than
                                                                            expected
                                                                            and
                                                                            some
                                                                            of
                                                                            them
                                                                            are
                                                                            not
                                                                            visible
                                                                            in
                                                                            the
                                                                            results.{" "}
                                                                            <UQLink
                                                                                url={
                                                                                    location.pathname
                                                                                }
                                                                            >
                                                                                Please
                                                                                refresh
                                                                                the
                                                                                screen
                                                                            </UQLink>
                                                                            .
                                                                        </>
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                        {dowloadingFailedMessage && (
                                                            <div className="fixed-toast-wrapper">
                                                                <ToastMessage
                                                                    type={
                                                                        "error"
                                                                    }
                                                                    closable="true"
                                                                    onClose={() => {
                                                                        let prototype_download_failed_messages =
                                                                            ReactSession.get(
                                                                                "prototype_download_failed_messages"
                                                                            ) || {
                                                                                [test.id]: false,
                                                                            };
                                                                        prototype_download_failed_messages[
                                                                            test.id
                                                                        ] = true;

                                                                        ReactSession.set(
                                                                            "prototype_download_failed_messages",
                                                                            prototype_download_failed_messages
                                                                        );

                                                                        setDowloadingFailedMessage(
                                                                            false
                                                                        );
                                                                    }}
                                                                    message={
                                                                        "We’re unable to load your Figma prototype and some images may not be visible in the results."
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                        {activePrototypeDataArray &&
                                                            activePrototypeDataArray.commonPath.map(
                                                                (
                                                                    items,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <>
                                                                            <div className="prototype-commont-path-repeat">
                                                                                <div className="prototype-arrow-hold d-flex align-items-center justify-content-between">
                                                                                    <div
                                                                                        className={`prototype-path-info-top d-flex `}
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {/*completion & skip rate will be shown only for task flow prototype*/}
                                                                                        {taskSetting &&
                                                                                            taskSetting.objective ===
                                                                                                "closed" && (
                                                                                                <>
                                                                                                    <div
                                                                                                        className={`common-path-info-data-repeat d-flex align-items-center success-path`}
                                                                                                    >
                                                                                                        {items.is_success ? (
                                                                                                            <>
                                                                                                                <Icon
                                                                                                                    size={
                                                                                                                        "medium"
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        "success"
                                                                                                                    }
                                                                                                                    colorClass={
                                                                                                                        "success-green-200-svg"
                                                                                                                    }
                                                                                                                />
                                                                                                                <Text
                                                                                                                    type={
                                                                                                                        "body-text-3"
                                                                                                                    }
                                                                                                                    cssClasses="green-text"
                                                                                                                    fontWeight={
                                                                                                                        "medium-font"
                                                                                                                    }
                                                                                                                    children={
                                                                                                                        "Success path"
                                                                                                                    }
                                                                                                                />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <Icon
                                                                                                                    size={
                                                                                                                        "medium"
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        "error"
                                                                                                                    }
                                                                                                                    colorClass={
                                                                                                                        "danger-200-svg"
                                                                                                                    }
                                                                                                                />
                                                                                                                <Text
                                                                                                                    type={
                                                                                                                        "body-text-3"
                                                                                                                    }
                                                                                                                    cssClasses="red-text"
                                                                                                                    fontWeight={
                                                                                                                        "medium-font"
                                                                                                                    }
                                                                                                                    children={
                                                                                                                        "Failure path"
                                                                                                                    }
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </>
                                                                                            )}

                                                                                        <div
                                                                                            className={`common-path-info-data-repeat d-flex align-items-center average-time none-for-mobile`}
                                                                                        >
                                                                                            <Icon
                                                                                                size={
                                                                                                    "medium"
                                                                                                }
                                                                                                value={
                                                                                                    "time"
                                                                                                }
                                                                                            />
                                                                                            <Text
                                                                                                type={
                                                                                                    "body-text-3"
                                                                                                }
                                                                                                fontWeight={
                                                                                                    "medium-font"
                                                                                                }
                                                                                                children={`Average time spent: ${items.commonPathTravelAverageTime}s`}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            className={`common-path-info-data-repeat d-flex align-items-center average-time none-for-desktop`}
                                                                                        >
                                                                                            <Icon
                                                                                                size={
                                                                                                    "medium"
                                                                                                }
                                                                                                value={
                                                                                                    "time"
                                                                                                }
                                                                                            />
                                                                                            <Text
                                                                                                type={
                                                                                                    "body-text-3"
                                                                                                }
                                                                                                fontWeight={
                                                                                                    "medium-font"
                                                                                                }
                                                                                                children={`Ave. time spent: ${items.commonPathTravelAverageTime}s`}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            className={`common-path-info-data-repeat d-flex align-items-center participant`}
                                                                                        >
                                                                                            <Icon
                                                                                                size={
                                                                                                    "medium"
                                                                                                }
                                                                                                value={
                                                                                                    "group"
                                                                                                }
                                                                                            />
                                                                                            <Text
                                                                                                type={
                                                                                                    "body-text-3"
                                                                                                }
                                                                                                fontWeight={
                                                                                                    "medium-font"
                                                                                                }
                                                                                                children={`Participants: ${items.total_participants}`}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            className={`common-path-info-data-repeat d-flex align-items-center total-screens`}
                                                                                        >
                                                                                            {test.device ==
                                                                                                "all" && (
                                                                                                <Icon
                                                                                                    value={
                                                                                                        "desktop-and-phone"
                                                                                                    }
                                                                                                    size={
                                                                                                        "medium"
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                            {test.device ==
                                                                                                "mobile" && (
                                                                                                <Icon
                                                                                                    value={
                                                                                                        "phone"
                                                                                                    }
                                                                                                    size={
                                                                                                        "medium"
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                            {test.device ==
                                                                                                "desktop" && (
                                                                                                <Icon
                                                                                                    value={
                                                                                                        "tablet"
                                                                                                    }
                                                                                                    size="medium"
                                                                                                />
                                                                                            )}
                                                                                            <Text
                                                                                                type={
                                                                                                    "body-text-3"
                                                                                                }
                                                                                                fontWeight={
                                                                                                    "medium-font"
                                                                                                }
                                                                                            >
                                                                                                {`${
                                                                                                    items
                                                                                                        .pathTravel
                                                                                                        .length ===
                                                                                                    1
                                                                                                        ? "Screen:"
                                                                                                        : "Screens:"
                                                                                                } ${
                                                                                                    items
                                                                                                        .pathTravel
                                                                                                        .length
                                                                                                }`}
                                                                                            </Text>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className={`dropdown-header-arrow-buttons mobile-nav-arrows prototype-arrow none-for-mobile ${
                                                                                            refs
                                                                                                .current[
                                                                                                index
                                                                                            ] &&
                                                                                            refs
                                                                                                .current[
                                                                                                index
                                                                                            ]
                                                                                                .scrollLeft ===
                                                                                                0 &&
                                                                                            refs
                                                                                                .current[
                                                                                                index
                                                                                            ]
                                                                                                .scrollWidth ===
                                                                                                parseInt(
                                                                                                    refs
                                                                                                        .current[
                                                                                                        index
                                                                                                    ]
                                                                                                        .scrollLeft +
                                                                                                        refs
                                                                                                            .current[
                                                                                                            index
                                                                                                        ]
                                                                                                            .clientWidth
                                                                                                )
                                                                                                ? "d-none"
                                                                                                : ""
                                                                                        }`}
                                                                                    >
                                                                                        <div className="bd-que-slide-arrow">
                                                                                            <a
                                                                                                onClick={() => {
                                                                                                    handleScroll(
                                                                                                        "left",
                                                                                                        refs
                                                                                                            .current[
                                                                                                            index
                                                                                                        ],
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                                className={`${
                                                                                                    refs
                                                                                                        .current[
                                                                                                        index
                                                                                                    ] &&
                                                                                                    refs
                                                                                                        .current[
                                                                                                        index
                                                                                                    ]
                                                                                                        .scrollLeft ==
                                                                                                        0
                                                                                                        ? "disabled-link"
                                                                                                        : ""
                                                                                                } dropdown-toggle-disabled`}
                                                                                            >
                                                                                                {/*{console.log(refs.current[index] && refs.current[index] !== undefined?refs.current[index].scrollLeft:'none')}*/}
                                                                                                {/*{refs.current[index] && refs.current[index].scrollLeft}*/}
                                                                                                <Icon
                                                                                                    value="back-arrow"
                                                                                                    size="small"
                                                                                                    colorClass={
                                                                                                        "gray-50-svg dropdown-toggle-disabled"
                                                                                                    }
                                                                                                />
                                                                                            </a>
                                                                                            <a
                                                                                                onClick={() => {
                                                                                                    handleScroll(
                                                                                                        "right",
                                                                                                        refs
                                                                                                            .current[
                                                                                                            index
                                                                                                        ],
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                                className={`${
                                                                                                    refs
                                                                                                        .current[
                                                                                                        index
                                                                                                    ] &&
                                                                                                    (refs
                                                                                                        .current[
                                                                                                        index
                                                                                                    ]
                                                                                                        .scrollWidth ||
                                                                                                        refs
                                                                                                            .current[
                                                                                                            index
                                                                                                        ]
                                                                                                            .scrollWidth -
                                                                                                            1) ==
                                                                                                        parseInt(
                                                                                                            refs
                                                                                                                .current[
                                                                                                                index
                                                                                                            ]
                                                                                                                .scrollLeft +
                                                                                                                refs
                                                                                                                    .current[
                                                                                                                    index
                                                                                                                ]
                                                                                                                    .clientWidth
                                                                                                        )
                                                                                                        ? "disabled-link"
                                                                                                        : ""
                                                                                                } dropdown-toggle-disabled`}
                                                                                            >
                                                                                                <Icon
                                                                                                    value="forward-arrow"
                                                                                                    size="small"
                                                                                                    colorClass={
                                                                                                        "gray-50-svg dropdown-toggle-disabled"
                                                                                                    }
                                                                                                />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span
                                                                                        className={
                                                                                            "d-none"
                                                                                        }
                                                                                    >
                                                                                        {setTimeout(
                                                                                            () => {
                                                                                                ScrollFirstTimeOnly(
                                                                                                    refs
                                                                                                        .current[
                                                                                                        index
                                                                                                    ],
                                                                                                    index
                                                                                                );
                                                                                            },
                                                                                            1000
                                                                                        )}
                                                                                    </span>
                                                                                </div>

                                                                                <div
                                                                                    className={`proto-common-path-row ${
                                                                                        test.device ==
                                                                                        "mobile"
                                                                                            ? "prototype-results-mobile-device"
                                                                                            : ""
                                                                                    }`}
                                                                                    ref={(
                                                                                        element
                                                                                    ) => {
                                                                                        refs.current[
                                                                                            index
                                                                                        ] =
                                                                                            element;
                                                                                    }}
                                                                                    onScroll={() =>
                                                                                        handleScroll(
                                                                                            "mouse",
                                                                                            refs
                                                                                                .current[
                                                                                                index
                                                                                            ],
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {items.pathTravel.map(
                                                                                        (
                                                                                            PathItems,
                                                                                            indexPath,
                                                                                            arr
                                                                                        ) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className={`common-path-repeat ${
                                                                                                        items.is_success &&
                                                                                                        arr.length -
                                                                                                            1 ===
                                                                                                            indexPath
                                                                                                            ? "is_selected_screen"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    key={
                                                                                                        indexPath
                                                                                                    }
                                                                                                >
                                                                                                    <div className=" common-path-content ">
                                                                                                        <div
                                                                                                            className={`proto-common-path-img-preview ${
                                                                                                                PathItems.is_placeholder ==
                                                                                                                "no"
                                                                                                                    ? "image-preview"
                                                                                                                    : ""
                                                                                                            }`}
                                                                                                        >
                                                                                                            {PathItems.is_placeholder ==
                                                                                                                "no" && (
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        PathItems.img
                                                                                                                            ? process
                                                                                                                                  .env
                                                                                                                                  .REACT_APP_IMG_URL +
                                                                                                                              PathItems.img
                                                                                                                            : process
                                                                                                                                  .env
                                                                                                                                  .REACT_APP_URL +
                                                                                                                              "img/default-placeholder-node-img.png"
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                            {PathItems.is_placeholder ==
                                                                                                                "yes" && (
                                                                                                                <div className="path-preview-not-available">
                                                                                                                    <Icon
                                                                                                                        value={
                                                                                                                            "error"
                                                                                                                        }
                                                                                                                        size={
                                                                                                                            "medium"
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <Text
                                                                                                                        type={
                                                                                                                            "caption"
                                                                                                                        }
                                                                                                                        fontWeight={
                                                                                                                            "medium-font"
                                                                                                                        }
                                                                                                                    >
                                                                                                                        Preview
                                                                                                                        not
                                                                                                                        available
                                                                                                                    </Text>
                                                                                                                </div>
                                                                                                            )}

                                                                                                            {PathItems.is_placeholder ==
                                                                                                                "no" && (
                                                                                                                <div className="prototype-preview-btn">
                                                                                                                    <Button
                                                                                                                        type={
                                                                                                                            "primary"
                                                                                                                        }
                                                                                                                        cssclass={
                                                                                                                            "white-bg-btn"
                                                                                                                        }
                                                                                                                        size="small"
                                                                                                                        iconLeft={
                                                                                                                            <Icon
                                                                                                                                size={
                                                                                                                                    "small"
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    "eye"
                                                                                                                                }
                                                                                                                                colorClass={
                                                                                                                                    "gray-900-svg"
                                                                                                                                }
                                                                                                                            />
                                                                                                                        }
                                                                                                                        label={
                                                                                                                            "Preview"
                                                                                                                        }
                                                                                                                        onClick={() => {
                                                                                                                            setPreviewModal(
                                                                                                                                {
                                                                                                                                    ...previewModal,
                                                                                                                                    open: true,
                                                                                                                                    img: PathItems.img
                                                                                                                                        ? process
                                                                                                                                              .env
                                                                                                                                              .REACT_APP_IMG_URL +
                                                                                                                                          PathItems.img
                                                                                                                                        : process
                                                                                                                                              .env
                                                                                                                                              .REACT_APP_URL +
                                                                                                                                          "img/default-placeholder-node-img.png",
                                                                                                                                }
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="common-path-content-footer">
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "caption"
                                                                                                                }
                                                                                                                children={
                                                                                                                    PathItems.name
                                                                                                                }
                                                                                                            />

                                                                                                            <div
                                                                                                                className={`data-time d-flex`}
                                                                                                            >
                                                                                                                <Icon
                                                                                                                    size={
                                                                                                                        "small"
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        "time"
                                                                                                                    }
                                                                                                                />
                                                                                                                <Text
                                                                                                                    cssClasses={
                                                                                                                        "light-dark-text"
                                                                                                                    }
                                                                                                                    type={
                                                                                                                        "caption"
                                                                                                                    }
                                                                                                                    children={`${PathItems.time}s`}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                                {/*Common path end*/}

                                                {/*Behaviour start*/}
                                                {/*Common path start*/}
                                                <div
                                                    className={`prototype-behavior`}
                                                >
                                                    <div
                                                        className={`behavior-header`}
                                                    >
                                                        <Text
                                                            type={"h4"}
                                                            children={
                                                                "Behaviour"
                                                            }
                                                        />
                                                    </div>

                                                    <div id={"behavior"}></div>
                                                    <div
                                                        className={`prototype-behavior-content mt-40`}
                                                    >
                                                        <div
                                                            className={`behavior-content-top d-flex justify-content-between`}
                                                        >
                                                            <div
                                                                className={`behavior-top-left-content d-flex`}
                                                            >
                                                                <RadioButton
                                                                    id="open_image_toggle"
                                                                    isChecked={
                                                                        behaviorType ===
                                                                        "image"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    label={
                                                                        <Text
                                                                            display="inline"
                                                                            type="body-text-2"
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                            cssClasses={
                                                                                "d-flex align-items-center radio-tab-icon"
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                value={
                                                                                    "image"
                                                                                }
                                                                                colorClass={
                                                                                    "gray-700-svg"
                                                                                }
                                                                            />{" "}
                                                                            Image
                                                                        </Text>
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setBehaviorType(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    value={
                                                                        "image"
                                                                    }
                                                                />

                                                                <RadioButton
                                                                    id="open_heatmap_toggle"
                                                                    isChecked={
                                                                        behaviorType ===
                                                                        "heatmap"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    label={
                                                                        <Text
                                                                            display="inline"
                                                                            type="body-text-2"
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                            cssClasses={
                                                                                "d-flex align-items-center radio-tab-icon"
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                value={
                                                                                    "Heatmap"
                                                                                }
                                                                                colorClass={
                                                                                    "gray-700-svg"
                                                                                }
                                                                            />{" "}
                                                                            Heatmap
                                                                        </Text>
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setBehaviorType(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    value={
                                                                        "heatmap"
                                                                    }
                                                                />

                                                                <RadioButton
                                                                    id="open_clickmap_toggle"
                                                                    isChecked={
                                                                        behaviorType ===
                                                                        "clickmap"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    label={
                                                                        <Text
                                                                            display="inline"
                                                                            type="body-text-2"
                                                                            fontWeight={
                                                                                "medium-font"
                                                                            }
                                                                            cssClasses={
                                                                                "d-flex align-items-center radio-tab-icon"
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                value={
                                                                                    "click"
                                                                                }
                                                                                colorClass={
                                                                                    "gray-700-svg"
                                                                                }
                                                                            />{" "}
                                                                            Clickmap
                                                                        </Text>
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setBehaviorType(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    value={
                                                                        "clickmap"
                                                                    }
                                                                />
                                                            </div>

                                                            <div
                                                                className={`behavior-top-right-content d-flex`}
                                                            >
                                                                {/* {(behaviorType === "image") &&
                                                        <div className={`prototype-behavior-screen-size-content`}>
                                                            <DropdownComponent
                                                                name="screenSize"
                                                                label="Select size"
                                                                onChange={(data) => {
                                                                    setActiveScreenSize(data.value);
                                                                }}
                                                                options={screenSizes}
                                                                value={activeScreenSize}
                                                                isClearable={false}
                                                            />
                                                        </div>
                                                        } */}

                                                                <div
                                                                    className={`prototype-behavior-screen-size-content`}
                                                                >
                                                                    <DropdownComponent
                                                                        name="screenSize"
                                                                        label="Select size"
                                                                        onChange={(
                                                                            data
                                                                        ) => {
                                                                            setActiveScreenSize(
                                                                                data.value
                                                                            );
                                                                        }}
                                                                        options={
                                                                            screenSizes
                                                                        }
                                                                        value={
                                                                            activeScreenSize
                                                                        }
                                                                        isClearable={
                                                                            false
                                                                        }
                                                                    />
                                                                </div>

                                                                {behaviorType !==
                                                                    "image" && (
                                                                    <Button
                                                                        size={
                                                                            "medium"
                                                                        }
                                                                        type={
                                                                            "secondary"
                                                                        }
                                                                        iconLeft={
                                                                            <Icon
                                                                                value={
                                                                                    "download"
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            "Download Image"
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            handleDownload();
                                                                            //downloadPng(behaviorType);
                                                                        }}
                                                                        state={
                                                                            !checkIfDownloadDisabled()
                                                                                ? "disabled"
                                                                                : ""
                                                                        }
                                                                    />
                                                                )}

                                                                <SearchDropdown
                                                                    className={
                                                                        "ml-20"
                                                                    }
                                                                    label={
                                                                        "Select screen"
                                                                    }
                                                                    value={
                                                                        activeScreenOptionVal
                                                                    }
                                                                    onChange={(
                                                                        item_new
                                                                    ) => {
                                                                        var item =
                                                                            {
                                                                                label: item_new,
                                                                                value: item_new,
                                                                            };
                                                                        var selectItem =
                                                                            activeScreenOptionArray.find(
                                                                                (
                                                                                    x
                                                                                ) =>
                                                                                    x.value ===
                                                                                    item.value
                                                                            );

                                                                        // console.log(selectItem)
                                                                        if (
                                                                            selectItem
                                                                        ) {
                                                                            setActiveScreenOption(
                                                                                selectItem.node_id
                                                                            );
                                                                        }

                                                                        setActiveScreenOptionVal(
                                                                            item.value
                                                                        );
                                                                        //setBehaviorType("image");
                                                                    }}
                                                                    options={
                                                                        screenOption
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={`behavior-main-slider-content dropdown-header-arrow-buttons d-block ${
                                                                behaviorType ===
                                                                "image"
                                                                    ? ""
                                                                    : "heatmap-clickmap-data-none"
                                                            }`}
                                                        >
                                                            {activePrototypeDataArray.behaviourData &&
                                                                activePrototypeDataArray.behaviourData.map(
                                                                    (items) => {
                                                                        return (
                                                                            <>
                                                                                {items.node_id ===
                                                                                    activeScreenOption && (
                                                                                    <>
                                                                                        <div
                                                                                            className={`prototype-map-slider-wrap ${
                                                                                                behaviorType ===
                                                                                                "image"
                                                                                                    ? "w-100"
                                                                                                    : ""
                                                                                            }`}
                                                                                        >
                                                                                            <div
                                                                                                ref={
                                                                                                    exportImageRef
                                                                                                }
                                                                                                className={`${
                                                                                                    behaviorType ===
                                                                                                    "image"
                                                                                                        ? ""
                                                                                                        : "d-none"
                                                                                                }`}
                                                                                            >
                                                                                                {items.is_placeholder ==
                                                                                                    "no" && (
                                                                                                    <img
                                                                                                        src={
                                                                                                            process
                                                                                                                .env
                                                                                                                .REACT_APP_IMG_URL +
                                                                                                            items.img
                                                                                                        }
                                                                                                        style={{
                                                                                                            maxWidth:
                                                                                                                activeScreenSize ===
                                                                                                                "Actual size"
                                                                                                                    ? "unset"
                                                                                                                    : "100%",
                                                                                                            display:
                                                                                                                "table",
                                                                                                            margin: "0 auto",
                                                                                                            maxHeight:
                                                                                                                activeScreenSize ===
                                                                                                                "Fit to canvas"
                                                                                                                    ? "600px"
                                                                                                                    : "unset",
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                                {items.is_placeholder ==
                                                                                                    "yes" && (
                                                                                                    <div className="path-preview-not-available">
                                                                                                        <div className="path-preview-not-available-inner">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "error"
                                                                                                                }
                                                                                                                size={
                                                                                                                    "large"
                                                                                                                }
                                                                                                            />
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "subtitle-1"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                Preview
                                                                                                                not
                                                                                                                available
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>

                                                                                            <div
                                                                                                ref={
                                                                                                    exportHeatmapRef
                                                                                                }
                                                                                                className={`heatmapImage ${
                                                                                                    behaviorType ===
                                                                                                    "heatmap"
                                                                                                        ? ""
                                                                                                        : "d-none"
                                                                                                }`}
                                                                                                style={{
                                                                                                    width: "0px",
                                                                                                    height: "0px",
                                                                                                }}
                                                                                                //style={{width:(activeScreenWidth)?activeScreenWidth+'px':'100%',height:(activeScreenHeight)?activeScreenHeight+'px':'100%'}}
                                                                                            >
                                                                                                {items.is_placeholder ==
                                                                                                    "no" && (
                                                                                                    <img
                                                                                                        ref={
                                                                                                            imageHeatmapRef
                                                                                                        }
                                                                                                        id="imageid"
                                                                                                        src={
                                                                                                            process
                                                                                                                .env
                                                                                                                .REACT_APP_IMG_URL +
                                                                                                            items.img
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                                {items.is_placeholder ==
                                                                                                    "yes" && (
                                                                                                    <div className="path-preview-not-available">
                                                                                                        <div className="path-preview-not-available-inner">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "error"
                                                                                                                }
                                                                                                                size={
                                                                                                                    "large"
                                                                                                                }
                                                                                                            />
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "subtitle-1"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                Preview
                                                                                                                not
                                                                                                                available
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>

                                                                                            <div
                                                                                                ref={
                                                                                                    exportClickmapRef
                                                                                                }
                                                                                                className={`clickMapImage position-relative ${
                                                                                                    behaviorType ===
                                                                                                    "clickmap"
                                                                                                        ? ""
                                                                                                        : "d-none"
                                                                                                }`}
                                                                                                style={{
                                                                                                    width: "0px",
                                                                                                    height: "0px",
                                                                                                }}
                                                                                                //style={{width:(activeScreenWidth)?activeScreenWidth+'px':'100%',height:(activeScreenHeight)?activeScreenHeight+'px':'100%'}}
                                                                                            >
                                                                                                {items.is_placeholder ==
                                                                                                    "no" && (
                                                                                                    <img
                                                                                                        ref={
                                                                                                            imageClickmapRef
                                                                                                        }
                                                                                                        src={
                                                                                                            process
                                                                                                                .env
                                                                                                                .REACT_APP_IMG_URL +
                                                                                                            items.img
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                                {items.is_placeholder ==
                                                                                                    "yes" && (
                                                                                                    <div className="path-preview-not-available">
                                                                                                        <div className="path-preview-not-available-inner">
                                                                                                            <Icon
                                                                                                                value={
                                                                                                                    "error"
                                                                                                                }
                                                                                                                size={
                                                                                                                    "large"
                                                                                                                }
                                                                                                            />
                                                                                                            <Text
                                                                                                                type={
                                                                                                                    "subtitle-1"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "medium-font"
                                                                                                                }
                                                                                                            >
                                                                                                                Preview
                                                                                                                not
                                                                                                                available
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>

                                                                                            {/*heatmap & clickmap show*/}
                                                                                        </div>

                                                                                        {heatmapImageLoading &&
                                                                                        behaviorType !==
                                                                                            "image" ? (
                                                                                            <div className="page-loader accountloading-box result-sections-loader">
                                                                                                <LoadingIcon />
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={
                                                                                                    "heatmap-clickmap-canvas-wrapper"
                                                                                                }
                                                                                                id={
                                                                                                    "heatmap-clickmap-canvas-wrapper"
                                                                                                }
                                                                                            >
                                                                                                {items.is_placeholder ==
                                                                                                    "no" && (
                                                                                                    <canvas
                                                                                                        ref={
                                                                                                            exportRef
                                                                                                        }
                                                                                                        className={`${
                                                                                                            behaviorType ===
                                                                                                            "image"
                                                                                                                ? "d-none"
                                                                                                                : ""
                                                                                                        }`}
                                                                                                        id="my-canvas"
                                                                                                        style={{
                                                                                                            maxWidth:
                                                                                                                activeScreenSize ===
                                                                                                                "Actual size"
                                                                                                                    ? "unset"
                                                                                                                    : "100%",
                                                                                                            display:
                                                                                                                behaviorType ==
                                                                                                                "image"
                                                                                                                    ? "d-none"
                                                                                                                    : "table",
                                                                                                            margin: "0 auto",
                                                                                                            maxHeight:
                                                                                                                activeScreenSize ===
                                                                                                                "Fit to canvas"
                                                                                                                    ? "600px"
                                                                                                                    : "unset",
                                                                                                        }}
                                                                                                        width={
                                                                                                            activeScreenWidth
                                                                                                                ? activeScreenWidth +
                                                                                                                  "px"
                                                                                                                : "0"
                                                                                                        }
                                                                                                        height={
                                                                                                            activeScreenHeight
                                                                                                                ? activeScreenHeight +
                                                                                                                  "px"
                                                                                                                : "0"
                                                                                                        }
                                                                                                    ></canvas>
                                                                                                )}
                                                                                                {items.is_placeholder ==
                                                                                                    "yes" &&
                                                                                                    behaviorType !==
                                                                                                        "image" && (
                                                                                                        <div className="path-preview-not-available">
                                                                                                            <div className="path-preview-not-available-inner">
                                                                                                                <Icon
                                                                                                                    value={
                                                                                                                        "error"
                                                                                                                    }
                                                                                                                    size={
                                                                                                                        "large"
                                                                                                                    }
                                                                                                                />
                                                                                                                <Text
                                                                                                                    type={
                                                                                                                        "subtitle-1"
                                                                                                                    }
                                                                                                                    fontWeight={
                                                                                                                        "medium-font"
                                                                                                                    }
                                                                                                                >
                                                                                                                    Preview
                                                                                                                    not
                                                                                                                    available
                                                                                                                </Text>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>

                                                        <div className="slider-time-bottom">
                                                            <div className="slide-count-info light-dark-text medium-font">
                                                                <Icon
                                                                    size={
                                                                        "medium"
                                                                    }
                                                                    value={
                                                                        "time"
                                                                    }
                                                                />
                                                                Average time
                                                                spent
                                                                <span>
                                                                    {averageTaskTime(
                                                                        activePrototypeDataArray.prototypeTaskResultsAverageTime
                                                                    )}
                                                                </span>
                                                            </div>

                                                            {activePrototypeDataArray.behaviourData &&
                                                                activePrototypeDataArray.behaviourData.map(
                                                                    (items) => {
                                                                        return (
                                                                            <>
                                                                                {items.node_id ===
                                                                                    activeScreenOption && (
                                                                                    <div className="slide-count-info light-dark-text medium-font">
                                                                                        <Icon
                                                                                            size={
                                                                                                "medium"
                                                                                            }
                                                                                            value={
                                                                                                "click"
                                                                                            }
                                                                                        />
                                                                                        Average
                                                                                        clicks
                                                                                        <span>
                                                                                            {Math.ceil(
                                                                                                items.averageClickNode
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>

                                                        {/*<div className="heatmapImage">*/}
                                                        {/*    <h1>Hello CodeSandbox</h1>*/}
                                                        {/*    <h2>Start editing to see some magic happen!</h2>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prototype-followup-section">
                                        <Text cssClasses={"pt-5"} type={"h4"}>
                                            {taskSetting &&
                                            taskSetting.objective === "closed"
                                                ? "Follow up question"
                                                : "Questions"}
                                        </Text>
                                        {taskArray[isActiveTask] && (
                                            <PrototypeTestFollowupQuestionsResult
                                                resultData={
                                                    taskArray[isActiveTask][
                                                        "results_data"
                                                    ]
                                                }
                                                resultCount={resultCount}
                                                settingType={
                                                    taskSetting &&
                                                    taskSetting.objective ===
                                                        "closed"
                                                        ? "closed"
                                                        : "open"
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {!sectionLoading && resultCount === 0 && (
                                <div
                                    className={`no-participants-data no-questions-data`}
                                >
                                    <div
                                        className="no-page-data-wrap"
                                        style={{ marginTop: "80px" }}
                                    >
                                        <div className="no-data-icon">
                                            <Icon
                                                value={"Attention"}
                                                colorClass={"gray-700-svg"}
                                                size={"large"}
                                            />
                                        </div>
                                        <Text
                                            type={"subtitle-1"}
                                            fontWeight="medium-font"
                                        >
                                            Details are not available at the
                                            moment as the test didn’t receive
                                            any response yet. Please come back
                                            later.
                                        </Text>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!isSharedLink && (
                <a
                    onClick={(e) => {
                        e.preventDefault();

                        setShowSupportPanel(true);
                    }}
                    href={process.env.REACT_APP_URL + "r/support"}
                    target="_blank"
                    className="support-button"
                >
                    <Icon
                        value={"support"}
                        colorClass={"gray-50-svg"}
                        size={"medium"}
                    />
                    Support
                </a>
            )}

            <Preview
                withMinimizeIcon={true}
                open={previewModal.open}
                loading={false}
                img={previewModal.img}
                close={() => {
                    setPreviewModal({ open: false, img: null, loading: false });
                }}
            />
        </LayoutResearcher>
    );
}
